import { useEffect, useCallback } from 'react'
import socketIOClient from 'socket.io-client'
import { dispatch } from 'store'
import { handleDatagridSocketCells, handleDatagridSocketGridData } from '../tailwindui/pages/datagrid/api'
import { useParams } from 'react-router'
var msgs = []
let socket

const WebSocket = (user) => {
    const token = localStorage.getItem('accessToken')
    const userId = user._id
    const handleLastMessage = (lastMessage) => {
        const path = window.location.pathname
        const pathArray = path.split('/dashboard/usertable/')
        const tableId = pathArray[1]
        if (lastMessage) {
            let lastMsg = lastMessage
            const scrapeMsg = lastMessage.message && typeof lastMessage.message === 'object' ? lastMessage.message : null
            if (lastMsg && lastMsg.type && lastMsg.type === 'tableChat' && lastMsg.tableId === tableId) {
                if (lastMsg.finished === true) {
                    dispatch({ type: 'SET_MSG_SENDING', payload: false })
                }
                dispatch({ type: 'ADD_MESSAGE_DG', payload: { ...lastMsg, sender: 'bot' } })
                if (lastMsg.action === 'update' && lastMsg.columns) {
                    // dispatch({ type: 'UPDATE_CELLS_DG', payload: lastMsg.columns })
                    // dispatch({ type: 'ADD_MESSAGE_DG', payload: { message: 'Columns updated', sender: 'bot' } })
                } else if (lastMsg.action === 'delete' && lastMsg.columns) {
                    dispatch({ type: 'DELETE_CELLS_DG', payload: lastMsg.columns })
                    dispatch({ type: 'ADD_MESSAGE_DG', payload: { message: 'Columns deleted', sender: 'bot' } })
                } else if (lastMsg.action === 'sort' && lastMsg.columns) {

                    dispatch({ type: 'HANDLE_SORT_BY_LLM', payload: lastMsg })
                    dispatch({ type: 'SET_FILTERS_DG', payload: [] })
                    
                    dispatch({ type: 'ADD_MESSAGE_DG', payload: { message: 'Record Sorted', sender: 'bot' } })
                } else if (lastMsg.action === 'filter' && lastMsg.columns) {
                    dispatch({ type: 'HANDLE_FILTER_BY_LLM', payload: lastMsg })
                    
                    dispatch({ type: 'ADD_MESSAGE_DG', payload: { message: 'Record Filtered', sender: 'bot' } })
                } else if (lastMsg.action === 'group' && lastMsg.columns) {
                    //to do
                    dispatch({ type: 'ADD_MESSAGE_DG', payload: { message: 'Record Grouping coming soon', sender: 'bot' } })
                } else if (lastMsg.action === 'graph' && lastMsg.columns) {
                    //to do
                    dispatch({ type: 'ADD_MESSAGE_DG', payload: { message: 'Graph coming soon', sender: 'bot' } })
                }
            } else if (scrapeMsg && scrapeMsg.type && scrapeMsg.type === 'SCRAPE_STATUS' && scrapeMsg.tableId === tableId) {
                    dispatch({ type: 'START_CHAT_DG', payload: true })
                    setTimeout(() => {
                        dispatch({ type: 'HANDLE_GRID_RERENDER' })
                    }, 300)
                
                dispatch({ type: 'ADD_MESSAGE_DG', payload: { message: scrapeMsg.message, sender: 'bot' } })
                if (scrapeMsg.isCompleted) {
                    dispatch({ type: 'SET_MSG_SENDING', payload: false })
                } else {
                    dispatch({ type: 'SET_MSG_SENDING', payload: true })
                }
            }
        }
    }
    const checkSocketConnection = useCallback(() => {
        if (socket && !socket.connected) {
            console.log('Socket disconnected, attempting to reconnect...')
            socket.connect()
        }
    }, [])

    const init = useCallback(async () => {
        // Configure socket with auth
        socket = socketIOClient.connect(process.env.REACT_APP_API_URL, {
            auth: {
                userId,
                token
            },
            autoConnect: true,
            reconnection: true,
            reconnectionAttempts: 5, // Number of attempts before giving up
            reconnectionDelay: 3000, // Time to wait between attempts (1 second)
            reconnectionDelayMax: 5000, // Max delay (5 seconds)
            timeout: 20000, // Time before the connection attempt times out (20 seconds)
        })
        socket.on('connect', () => {
            console.log('Socket connected:', socket.id)
            socket.emit('register', userId);
            socket.on('private_message', (data) => {
                console.log('Received private message:', data)
                msgs.push(data)
                dispatch({ type: 'SET_SOCKET_DATA', payload: data })
                handleDatagridSocketCells(data)
                handleLastMessage(data)
                if(data.message?.dataType === 'REFRESH_TABLE') {
                    dispatch({ type: 'REFRESH_TABLE', payload: data })
                }
                if(data.message?.dataType === 'REFRESH_TABLE_ROWS') {
                    dispatch({ type: 'REFRESH_TABLE_ROWS', payload: data })
                }
                if(data.message?.dataType === 'TABLE_STATUS') {
                    dispatch({ type: 'TABLE_STATUS', payload: data })
                }
                if(data.message?.dataType === 'UPDATE_TABLE_STATUS') {
                    dispatch({ type: 'SET_ENRICHMENT_STATUS', payload: data?.isProcessing === true ? 'STARTED': 'STOPPED' })
                }
            })
            
            dispatch({ type: 'SET_SOCKET_ID', payload: socket.id })
            dispatch({ type: 'SET_ROOM_ID', payload: 'private_message' })
        })

        socket.on('connect_error', (error) => {
            console.error('Socket connection error:', error.message)
            dispatch({ type: 'SOCKET_ERROR', payload: error.message })
        })

        socket.on('pong', () => {
            console.log('Received pong from server')
        })

        socket.on('unauthorized', (error) => {
            console.error('Socket authentication failed:', error.message)
            dispatch({ type: 'SOCKET_UNAUTHORIZED', payload: error.message })
            socket.disconnect()
        })
        socket.on('reconnect_attempt', (attempt) => {
            console.log(`Reconnection attempt ${attempt}`);
        });
        
        socket.on('reconnect', (attempt) => {
            console.log(`Successfully reconnected after ${attempt} attempts`);
        });
        socket.on('server_restart', () => {
            console.warn('Server was restarted. Reinitializing...');
            // Handle necessary reinitialization here
        });
    }, [userId, token])

    // Handle visibility change to check socket connection
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                checkSocketConnection()
            }
        }

        document.addEventListener('visibilitychange', handleVisibilityChange)
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange)
        }
    }, [checkSocketConnection])

    // Ping-pong mechanism
    useEffect(() => {
        let pingInterval
        if (socket) {
            pingInterval = setInterval(() => {
                if (socket.connected) {
                    socket.emit('ping')
                    console.log('Ping sent to server')
                }
            }, 6000) // Send ping every minute
        }

        return () => {
            if (pingInterval) {
                clearInterval(pingInterval)
            }
        }
    }, [])

    useEffect(() => {
        if (userId && token) {
            init()
        } else {
            console.error('Socket initialization failed: Missing userId or token')
        }

        const cl = setInterval(() => {
            if (msgs.length > 0) {
                const msgLength = msgs.length
                // get all message till length and remove them from array
                const getmsgs = msgs.slice(0, msgLength)
                msgs = msgs.slice(msgLength)
                handleDatagridSocketGridData(getmsgs)
            }
        }, 1000)

        return () => {
            if (socket) {
                socket.disconnect()
                dispatch({ type: 'CLEANUP_SOCKET' })
            }
            clearInterval(cl)
        }
    }, [userId, token, init])

    return {
        socket
    }
}
export default WebSocket
